import React from 'react';

const Flex = ({
  children,
  row,
  alignItems,
  justifyContent,
  style,
  ...props
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: row ? 'row' : 'column',
      alignItems: alignItems || 'flex-start',
      justifyContent: justifyContent || 'flex-start',
      ...style
    }}
    {...props}
  >
    {children}
  </div>
);

export default Flex;
